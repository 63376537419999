import { defineTokens } from '@chakra-ui/react';

export const fonts = defineTokens.fonts({
  heading: {
    value: `'Poppins', sans-serif`,
  },
  body: {
    value: `Roboto, sans-serif`,
  },
  mono: {
    value: `Roboto, sans-serif`,
  },
});
