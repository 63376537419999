import { defineTokens } from '@chakra-ui/react';

export const colors = defineTokens.colors({
  primary100: '#0B1435',
  primaryBoldScheme: {
    500: '#0B1435',
    default: '#0B1435',
  },
  primary: {
    50: 'rgba(11, 20, 53, 0.05)',
    100: 'rgba(11, 20, 53, 0.1)',
    200: 'rgba(11, 20, 53, 0.2)',
    300: 'rgba(11, 20, 53, 0.3)',
    400: 'rgba(11, 20, 53, 0.4)',
    500: 'rgba(11, 20, 53, 0.5)',
    600: 'rgba(11, 20, 53, 0.6)',
    700: 'rgba(11, 20, 53, 0.7)',
    800: 'rgba(11, 20, 53, 0.8)',
    900: 'rgba(11, 20, 53, 0.9)',
    default: '#0B1435',
  },
  secondary: {
    50: 'rgba(58, 68, 176, 0.05)',
    100: 'rgba(58, 68, 176, 0.1)',
    200: 'rgba(58, 68, 176, 0.2)',
    300: 'rgba(58, 68, 176, 0.3)',
    400: 'rgba(58, 68, 176, 0.4)',
    500: 'rgba(58, 68, 176, 0.5)',
    600: 'rgba(58, 68, 176, 0.6)',
    700: 'rgba(58, 68, 176, 0.7)',
    800: 'rgba(58, 68, 176, 0.8)',
    900: 'rgba(58, 68, 176, 0.9)',
    default: '#3A44B0',
  },
  tertiary: {
    50: 'rgba(232, 47, 235, 0.1)',
    100: 'rgba(232, 47, 235, 0.2)',
    200: 'rgba(232, 47, 235, 0.3)',
    300: 'rgba(232, 47, 235, 0.4)',
    400: 'rgba(232, 47, 235, 0.5)',
    500: 'rgba(232, 47, 235, 0.6)',
    600: 'rgba(232, 47, 235, 0.7)',
    700: 'rgba(232, 47, 235, 0.8)',
    800: 'rgba(232, 47, 235, 0.9)',
    900: '#e82feb',
    default: '#e82feb',
  },
  nav: {
    500: '#05314C',
    main: '#05314C',
    dark: '#0B1435',
    light: '#2544b4',
    text: '#ffffff',
    lightShadow: 'rgba(126, 114, 242, 0.35)',
    darkShadow: 'rgba(0, 0, 0, 0.25)',
    scrollBarBackgroundColor: '#05314C',
    active: '#FD5C38',
    horizontalBandBgColor: '#EBF0FF',
  },
  blackScheme: {
    50: 'rgba(9, 16, 29, 0.1)',
    100: 'rgba(9, 16, 29, 0.2)',
    200: 'rgba(9, 16, 29, 0.3)',
    300: 'rgba(9, 16, 29, 0.4)',
    400: 'rgba(9, 16, 29, 0.5)',
    500: 'rgba(9, 16, 29, 0.6)',
    600: 'rgba(9, 16, 29, 0.7)',
    700: 'rgba(9, 16, 29, 0.8)',
    800: 'rgba(9, 16, 29, 0.9)',
    900: '#09101D',
  },
  whiteBoldScheme: {
    50: '#FFF',
    100: '#FFF',
    200: '#FFF',
    300: '#FFF',
    400: '#FFF',
    500: '#FFF',
    600: '#FFF',
    700: '#FFF',
    800: '#FFF',
    900: '#FFFFFF',
  },
  neutral: {
    1: '#2C3A4B',
    2: '#394452',
    3: '#545D69',
    4: '#6D7580',
    5: '#858C94',
    6: '#A5ABB3',
    7: '#DADEE3',
    8: '#EBEEF2',
    9: '#F4F6F9',
    black: '#09101D',
    white: '#FFFFFF',
  },
  gray3: {
    default: '#828282',
  },
  darkGray: {
    default: '#343434',
  },
  graySubtle: {
    default: '#FAFAFA',
  },
  grayBorder: {
    default: '#CCC',
  },
  geekBlue: {
    50: '#F0F5FF',
    300: '#F0F5FF',
    500: '#2F54EB',
    600: '#2F54EB',
    default: '#2F54EB',
  },
  black: {
    value: '#09101D',
  },
  white: {
    value: '#FFFFFF',
  },
  accent: {
    1: '#FD5C38',
    2: '#2E5BFF',
    3: '#21DDB8',
    4: '#221874',
    5: '#7CC6D6',
    6: '#EA5455',
    7: '#21DDB847',
    8: '#2D6A6A1A',
    9: '#E9AD8C3B',
    10: '#2218741A',
    11: '#7CC6D640',
    12: '#E1604D26',
    one: {
      100: '#FD5C38',
      200: '#FD5C38',
      500: '#FD5C38',
      800: '#FFFFFF',
      default: '#FD5C38',
    },
    two: {
      500: '#2E5BFF',
      default: '#2E5BFF',
    },
    three: {
      500: '#21DDB8',
      default: '#21DDB8',
    },
    four: {
      500: '#221874',
      default: '#221874',
    },
    five: {
      500: '#7CC6D6',
      default: '#7CC6D6',
    },
    six: {
      500: '#EA5455',
      default: '#EA5455',
    },
    seven: {
      500: '#21DDB847',
      default: '#21DDB847',
    },
    eight: {
      500: '#2D6A6A1A',
      default: '#2D6A6A1A',
    },
    nine: {
      500: '#E9AD8C3B',
      default: '#E9AD8C3B',
    },
    ten: {
      500: '#2218741A',
      default: '#2218741A',
    },
    eleven: {
      500: '#7CC6D640',
      default: '#7CC6D640',
    },
    twelve: {
      500: '#E1604D26',
      default: '#E1604D26',
    },
  },
  actionPrimary: {
    500: '#031B4E',
    default: '#031B4E',
    hover: 'rgba(3, 27, 78, 0.8)',
    active: '#031B4E',
    disabled: 'rgba(11, 95, 255, 0.5)',
    hover10: 'rgba(0, 84, 240, 0.1)',
    active20: 'rgba(0, 75, 214, 0.2)',
    inverted: 'rgba(255, 255, 255, 1)',
    visited: 'rgba(46, 91, 255, 1)',
  },
  actionSecondary: {
    100: '#3A44B0',
    200: '#3A44B0',
    300: '#3A44B0',
    400: '#3A44B0',
    500: '#3A44B0',
    600: '#3A44B0',
    700: '#3A44B0',
    800: '#3A44B0',
    900: '#3A44B0',
    default: '#3A44B0',
    hover: 'rgba(58, 68, 176, 0.8)',
    blueButton: '#007eff',
    active: 'rgba(58, 68, 176, 0.7)',
    disabled: 'rgba(58, 68, 176, 0.5)',
    hover10: 'rgba(58, 68, 176, 0.3)',
    active20: 'rgba(58, 68, 176, 0.2)',
    inverted: 'rgba(255, 255, 255, 1)',
    visited: 'rgba(46, 91, 255, 1)',
  },
  actionTertiary: {
    50: 'rgba(165, 16, 16, 0.4)',
    100: 'rgba(165, 16, 16, 0.5)',
    200: 'rgba(165, 16, 16, 0.6)',
    300: 'rgba(165, 16, 16, 0.7)',
    400: 'rgba(165, 16, 16, 0.8)',
    500: 'rgba(165, 16, 16, 1)',
    600: 'rgba(165, 16, 16, 1)',
    700: 'rgba(165, 16, 16, 1)',
    800: 'rgba(165, 16, 16, 1)',
    900: '#05314C',
    default: '#05314C',
    hover: 'rgba(240, 245, 255, 0.8)',
    active: 'rgba(240, 245, 255, 0.7)',
    disabled: 'rgba(240, 245, 255, 0.5)',
    hover10: 'rgba(240, 245, 255, 0.3)',
    active20: 'rgba(240, 245, 255, 0.2)',
    inverted: '#FFFFFF',
    visited: '#05314C',
  },
  actionNeutral: {
    100: '#EBEEF2',
    200: '#EBEEF2',
    300: '#EBEEF2',
    400: '#EBEEF2',
    500: '#6D7580',
    600: '#6D7580',
    700: '#6D7580',
    800: '#6D7580',
    900: '#6D7580',
    default: '#6D7580',
    hover: '#858C94',
    active: '#798087',
    disabled: 'rgba(144, 152, 161, 0.7)',
    hover10: 'rgba(109, 117, 128, 0.1)',
    active20: 'rgba(109, 117, 128, 0.2)',
    inverted: '#FFFFFF',
    visited: '#5E38BA',
  },
  success: {
    500: '#00BA88',
    default: '#00BA88',
  },
  suggessBg: {
    500: '#EDF9F0',
    default: '#EDF9F0',
  },
  warning: {
    500: '#E79900',
    default: '#E79900',
  },
  warningBg: {
    500: '#FFFBE6',
    default: '#FFFBE6',
  },
  warningBorder: {
    500: '#F3C160',
    default: '#F3C160',
  },
  error: {
    500: '#DA1414',
    default: '#DA1414',
  },
  errorBg: {
    500: '#FEEFEF',
    default: '#FEEFEF',
  },
  info: {
    500: '#2E5AAC',
    default: '#2E5AAC',
  },
  infoBg: {
    500: '#EEF2FA',
    default: '#EEF2FA',
  },
  infoBorder: {
    500: '#89A7E0',
    default: '#89A7E0',
  },
  mainGradient: {
    50: '#0043FF',
    900: '#031B4E',
  },
  secondaryGradient: {
    50: '#0065FF',
    900: '#4BF2E6',
  },
  coolGradient: {
    50: '#05314C',
    900: '#9482FC',
  },
  card: {
    500: '#FAFAFA',
    default: '#FAFAFA',
  },
  multiSelectionItem: {
    color: '#FD5C38',
    borderColor: '#FD5C38',
    bgColor: '#FFFFFF',
  },
  quickFilter: {
    color: '#2F54EB',
    borderColor: '#2F54EB',
    bgColor: '#F0F5FF',
  },
  transparent: {
    value: 'transparent',
  },
  current: {
    value: 'currentColor',
  },
  whiteAlpha: {
    50: {
      value: 'rgba(255, 255, 255, 0.04)',
    },
    100: {
      value: 'rgba(255, 255, 255, 0.06)',
    },
    200: {
      value: 'rgba(255, 255, 255, 0.08)',
    },
    300: {
      value: 'rgba(255, 255, 255, 0.16)',
    },
    400: {
      value: 'rgba(255, 255, 255, 0.24)',
    },
    500: {
      value: 'rgba(255, 255, 255, 0.36)',
    },
    600: {
      value: 'rgba(255, 255, 255, 0.48)',
    },
    700: {
      value: 'rgba(255, 255, 255, 0.64)',
    },
    800: {
      value: 'rgba(255, 255, 255, 0.80)',
    },
    900: {
      value: 'rgba(255, 255, 255, 0.92)',
    },
    950: {
      value: 'rgba(255, 255, 255, 0.95)',
    },
  },
  blackAlpha: {
    50: {
      value: 'rgba(0, 0, 0, 0.04)',
    },
    100: {
      value: 'rgba(0, 0, 0, 0.06)',
    },
    200: {
      value: 'rgba(0, 0, 0, 0.08)',
    },
    300: {
      value: 'rgba(0, 0, 0, 0.16)',
    },
    400: {
      value: 'rgba(0, 0, 0, 0.24)',
    },
    500: {
      value: 'rgba(0, 0, 0, 0.36)',
    },
    600: {
      value: 'rgba(0, 0, 0, 0.48)',
    },
    700: {
      value: 'rgba(0, 0, 0, 0.64)',
    },
    800: {
      value: 'rgba(0, 0, 0, 0.80)',
    },
    900: {
      value: 'rgba(0, 0, 0, 0.92)',
    },
    950: {
      value: 'rgba(0, 0, 0, 0.95)',
    },
  },
  gray: {
    50: {
      value: '#fafafa',
    },
    100: {
      value: '#f4f4f5',
    },
    200: {
      value: '#e4e4e7',
    },
    300: {
      value: '#d4d4d8',
    },
    400: {
      value: '#a1a1aa',
    },
    500: {
      value: '#71717a',
    },
    600: {
      value: '#52525b',
    },
    700: {
      value: '#3f3f46',
    },
    800: {
      value: '#27272a',
    },
    900: {
      value: '#18181b',
    },
    950: {
      value: '#111111',
    },
  },
  red: {
    50: {
      value: '#fef2f2',
    },
    100: {
      value: '#fee2e2',
    },
    200: {
      value: '#fecaca',
    },
    300: {
      value: '#fca5a5',
    },
    400: {
      value: '#f87171',
    },
    500: {
      value: '#ef4444',
    },
    600: {
      value: '#dc2626',
    },
    700: {
      value: '#991919',
    },
    800: {
      value: '#511111',
    },
    900: {
      value: '#300c0c',
    },
    950: {
      value: '#1f0808',
    },
  },
  orange: {
    50: {
      value: '#fff7ed',
    },
    100: {
      value: '#ffedd5',
    },
    200: {
      value: '#fed7aa',
    },
    300: {
      value: '#fdba74',
    },
    400: {
      value: '#fb923c',
    },
    500: {
      value: '#f97316',
    },
    600: {
      value: '#ea580c',
    },
    700: {
      value: '#92310a',
    },
    800: {
      value: '#6c2710',
    },
    900: {
      value: '#3b1106',
    },
    950: {
      value: '#220a04',
    },
  },
  yellow: {
    50: {
      value: '#fefce8',
    },
    100: {
      value: '#fef9c3',
    },
    200: {
      value: '#fef08a',
    },
    300: {
      value: '#fde047',
    },
    400: {
      value: '#facc15',
    },
    500: {
      value: '#eab308',
    },
    600: {
      value: '#ca8a04',
    },
    700: {
      value: '#845209',
    },
    800: {
      value: '#713f12',
    },
    900: {
      value: '#422006',
    },
    950: {
      value: '#281304',
    },
  },
  green: {
    50: {
      value: '#f0fdf4',
    },
    100: {
      value: '#dcfce7',
    },
    200: {
      value: '#bbf7d0',
    },
    300: {
      value: '#86efac',
    },
    400: {
      value: '#4ade80',
    },
    500: {
      value: '#22c55e',
    },
    600: {
      value: '#16a34a',
    },
    700: {
      value: '#116932',
    },
    800: {
      value: '#124a28',
    },
    900: {
      value: '#042713',
    },
    950: {
      value: '#03190c',
    },
  },
  teal: {
    50: {
      value: '#f0fdfa',
    },
    100: {
      value: '#ccfbf1',
    },
    200: {
      value: '#99f6e4',
    },
    300: {
      value: '#5eead4',
    },
    400: {
      value: '#2dd4bf',
    },
    500: {
      value: '#14b8a6',
    },
    600: {
      value: '#0d9488',
    },
    700: {
      value: '#0c5d56',
    },
    800: {
      value: '#114240',
    },
    900: {
      value: '#032726',
    },
    950: {
      value: '#021716',
    },
  },
  blue: {
    50: {
      value: '#eff6ff',
    },
    100: {
      value: '#dbeafe',
    },
    200: {
      value: '#bfdbfe',
    },
    300: {
      value: '#a3cfff',
    },
    400: {
      value: '#60a5fa',
    },
    500: {
      value: '#3b82f6',
    },
    600: {
      value: '#2563eb',
    },
    700: {
      value: '#173da6',
    },
    800: {
      value: '#1a3478',
    },
    900: {
      value: '#14204a',
    },
    950: {
      value: '#0c142e',
    },
  },
  cyan: {
    50: {
      value: '#ecfeff',
    },
    100: {
      value: '#cffafe',
    },
    200: {
      value: '#a5f3fc',
    },
    300: {
      value: '#67e8f9',
    },
    400: {
      value: '#22d3ee',
    },
    500: {
      value: '#06b6d4',
    },
    600: {
      value: '#0891b2',
    },
    700: {
      value: '#0c5c72',
    },
    800: {
      value: '#134152',
    },
    900: {
      value: '#072a38',
    },
    950: {
      value: '#051b24',
    },
  },
  purple: {
    50: {
      value: '#faf5ff',
    },
    100: {
      value: '#f3e8ff',
    },
    200: {
      value: '#e9d5ff',
    },
    300: {
      value: '#d8b4fe',
    },
    400: {
      value: '#c084fc',
    },
    500: {
      value: '#a855f7',
    },
    600: {
      value: '#9333ea',
    },
    700: {
      value: '#641ba3',
    },
    800: {
      value: '#4a1772',
    },
    900: {
      value: '#2f0553',
    },
    950: {
      value: '#1a032e',
    },
  },
  pink: {
    50: {
      value: '#fdf2f8',
    },
    100: {
      value: '#fce7f3',
    },
    200: {
      value: '#fbcfe8',
    },
    300: {
      value: '#f9a8d4',
    },
    400: {
      value: '#f472b6',
    },
    500: {
      value: '#ec4899',
    },
    600: {
      value: '#db2777',
    },
    700: {
      value: '#a41752',
    },
    800: {
      value: '#6d0e34',
    },
    900: {
      value: '#45061f',
    },
    950: {
      value: '#2c0514',
    },
  },
});
