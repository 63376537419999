import { defineTokens } from "@chakra-ui/react"

export const blurs = defineTokens.blurs({
  none: {
    value: " ",
  },
  sm: {
    value: "4px",
  },
  md: {
    value: "8px",
  },
  lg: {
    value: "12px",
  },
  xl: {
    value: "16px",
  },
  "2xl": {
    value: "24px",
  },
  "3xl": {
    value: "40px",
  },
  "4xl": {
    value: "64px",
  },
})
