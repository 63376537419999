import { defineTokens } from '@chakra-ui/react';

export const lineHeights = defineTokens.lineHeights({
  normal: {
    value: 'normal',
  },
  none: {
    value: 1,
  },
  shorter: {
    value: 1.25,
  },
  short: {
    value: 1.375,
  },
  base: {
    value: 1.5,
  },
  tall: {
    value: 1.625,
  },
  taller: {
    value: '2',
  },
  3: {
    value: '.75rem',
  },
  4: {
    value: '1rem',
  },
  5: {
    value: '1.25rem',
  },
  6: {
    value: '1.5rem',
  },
  7: {
    value: '1.75rem',
  },
  8: {
    value: '2rem',
  },
  9: {
    value: '2.25rem',
  },
  10: {
    value: '2.5rem',
  },
  xl: {
    value: '26px',
  },
});
