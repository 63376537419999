import { createSelector } from '@reduxjs/toolkit';
import { subDays, subYears } from 'date-fns';

import { MARKET_FOCUS } from '../marketsConfig';
import { MARKETS } from '../../../config/markets';
import { VOLUME_GROUPINGS } from '../../../config/marketsVolumeGrouping';
import { filterRawOrdersByTimePeriod } from '../../../modules/order-utils';

// ------------------------------------
// BASIC SELECTORS
// ------------------------------------

// Private
const selectActiveMarketKey = state => state.markets.activeMarket;

// Public
/**
 * Whether the market page is loading
 * @param {*} state
 */
export const selectMarketsLoading = state => state.markets.loading;
/**
 * Any page-wide error for the market page
 * @param {*} state
 */
export const selectMarketsError = state => state.markets.error;
/**
 * The raw data stored in state, from requests.
 * @param {*} state
 */
export const selectMarketsDataRaw = state => state.markets.data;

export const selectMarketsYtdDataRaw = state => state.markets.ytdData;
/**
 * Which grouping strategy to use for the volume table.
 */
export const selectActiveVolumeGrouping = state => VOLUME_GROUPINGS[state.markets.activeVolumeGrouping];

/**
 * Options pertaining to volume groupings
 */
export const selectVolumeOptions = state => state.markets.volumeOptions;

/**
 * The current time period for the markets page. [Number, Number] array.
 */
export const selectMarketsTimePeriod = state => state.markets.timePeriod;

/**
 * The current currency for the markets page.
 */
export const selectActiveCurrency = state => state.markets.activeCurrency;

/**
 * The current protein for the markets page.
 */
export const selectActiveProtein = state => state.markets.activeProtein;

/**
 * The date mode for supply chain
 */
export const selectSupplyChainDateMode = state => state.markets.supplyChainDateMode;

/**
 * The perspective from which to view supply chain data.
 * @param {*} state
 */
export const selectSupplyChainViewMode = state => state.markets.supplyChainViewMode;

/**
 * Select the ID of the currently hovered location.
 * @param {*} state
 */
export const selectSupplyChainHoveredLocation = state => state.markets.supplyChainHoveredLocation;

/**
 * The raw markets data for the current time period. Needed because we
 * select more data than just the time period the user has selected for
 * the change on previous period calculations.
 */
export const selectMarketsCurrentPeriodDataRaw = createSelector(
  selectMarketsDataRaw,
  selectMarketsTimePeriod,
  (data, timePeriod) => data && filterRawOrdersByTimePeriod(data, timePeriod)
);

/**
 * Selects the time period before the current time period (each period is 1 week long)
 */
export const selectMarketsPreviousTimePeriod = createSelector(selectMarketsTimePeriod, ([start, end]) => [
  subDays(start, 7),
  subDays(end, 7),
]);

/**
 * The raw markets data for the previous week's time period (the week before
 * the currently selected time period).
 */
export const selectMarketsPreviousPeriodDataRaw = createSelector(
  selectMarketsDataRaw,
  selectMarketsPreviousTimePeriod,
  (data, timePeriod) => data && filterRawOrdersByTimePeriod(data, timePeriod)
);

/**
 * Selects the time period from 1 year up until the end of the currently selected period
 */
export const selectMarketsYearToDatePeriod = createSelector(selectMarketsTimePeriod, dates => [
  subYears(dates[1], 1),
  dates[1],
]);

/**
 * The available markets as market objects instead of a list of keys
 */
export const selectAvailableMarkets = state => {
  // For treasury page, just allow USA market.
  const isTreasuryPage = window.location?.pathname.split('/').find(n => n === 'treasury');
  const availableMarkets = isTreasuryPage ? ['USA'] : state.markets.availableMarkets;
  return availableMarkets.map(marketKey => MARKETS[marketKey]);
};

// Complex selectors

export const selectActiveMarket = createSelector(selectActiveMarketKey, marketKey => MARKETS[marketKey]);

/**
 * Select the active focus
 */
export const selectActiveFocus = createSelector(
  state => state.markets.activeFocus,
  selectActiveMarket,
  (activeFocusRaw, activeMarket) => {
    // We check the market object, and if it allows export we return whatever we have in state
    if (activeMarket.allowExportFocus) return activeFocusRaw;

    // If we can't select focus, short circuit to domestic only.
    return MARKET_FOCUS.DOMESTIC;
  }
);

/**
 * Select the USDA prices if it's enabled for the market, otherwise []
 */
export const selectUsdaPrices = createSelector(selectMarketsDataRaw, selectActiveMarket, (data, activeMarket) => {
  if (!activeMarket.includeUsdaCalculations) return [];
  if (!data) return [];
  return data.usdaPrices || [];
});

/**
 *  Select data for the sustainability metrics section
 */
export const selectSustainabilityMetrics = createSelector(
  selectMarketsCurrentPeriodDataRaw,
  selectActiveMarket,
  (data, activeMarket) => {
    if (!activeMarket.hasSustainabilityMetrics) return null;
    if (!data) return null;
    return data.sustainabilityMetrics || [];
  }
);

/**
 *  Select data for the supply chain incidents
 */
export const selectSupplyChainIncidents = createSelector(
  selectMarketsDataRaw,
  selectActiveMarket,
  selectMarketsTimePeriod,
  (data, activeMarket, timePeriod) => {
    if (!data || !data.supplyChainIncidents) return null;

    const incidentsFilteredByActiveMarket = data.supplyChainIncidents.filter(x => {
      if (
        x.parties_involved.packer_plants.some(p => p.packer_plant_country === activeMarket.key) ||
        x.parties_involved.grinders.some(p => p.grinder_country === activeMarket.key)
      ) {
        return true;
      }
      return false;
    });

    const incidents = incidentsFilteredByActiveMarket.filter(x => {
      if (x.start_date >= timePeriod[0] && x.start_date <= timePeriod[1]) {
        return true;
      }
      if ((x.end_date >= timePeriod[0] && x.end_date <= timePeriod[1]) || x.end_date === undefined) {
        return true;
      }
      return false;
    });

    return incidents;
  }
);

/**
 * Select the orders for the current focus.
 */
export const selectActiveFocusOrders = createSelector(
  selectMarketsDataRaw,
  selectActiveFocus,
  (rawData, activeFocus) => {
    if (!rawData) return [];
    return rawData.orders[activeFocus] || [];
  }
);

export const selectLocationsData = createSelector(selectMarketsDataRaw, rawData => {
  if (!rawData) return null;
  return rawData.locations;
});

/**
 * Select all the parameters required to fetch data.
 */
export const selectMarketFetchParameters = createSelector(
  selectActiveMarket,
  selectMarketsTimePeriod,
  selectActiveCurrency,
  (activeMarket, timePeriod, currency) => ({
    activeMarket,
    timePeriod,
    currency,
  })
);

/**
 * Select the options available for groupings in the volume table.
 */
export const selectVolumeGroups = createSelector(selectActiveMarket, selectActiveFocus, (activeMarket, activeFocus) => {
  if (!activeMarket.allowExportFocus) return activeMarket.volumeGroupings;
  const filtered = activeMarket.volumeGroupings.filter(g => {
    if (!g.onlyShowForMarketFocus) return true;
    return g.onlyShowForMarketFocus.includes(activeFocus);
  });

  return filtered;
});

/**
 * Values for the controls in the market supply chain card
 */
export const selectMarketSupplyChainControlState = createSelector(
  selectSupplyChainDateMode,
  selectSupplyChainViewMode,
  (dateMode, viewMode) => ({
    dateMode,
    viewMode,
  })
);

/**
 * Select the options available for groupings in the volume table.
 */
export const selectMarketMenuItems = createSelector(selectActiveMarket, activeMarket => {
  return activeMarket.menuItems;
});
