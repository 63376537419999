import { defineTokens } from "@chakra-ui/react"

export const spacing = defineTokens.spacing({
  "1": {
    value: "0.25rem",
  },
  "2": {
    value: "0.5rem",
  },
  "3": {
    value: "0.75rem",
  },
  "4": {
    value: "1rem",
  },
  "5": {
    value: "1.25rem",
  },
  "6": {
    value: "1.5rem",
  },
  "7": {
    value: "1.75rem",
  },
  "8": {
    value: "2rem",
  },
  "9": {
    value: "2.25rem",
  },
  "10": {
    value: "2.5rem",
  },
  "11": {
    value: "2.75rem",
  },
  "12": {
    value: "3rem",
  },
  "14": {
    value: "3.5rem",
  },
  "16": {
    value: "4rem",
  },
  "20": {
    value: "5rem",
  },
  "24": {
    value: "6rem",
  },
  "28": {
    value: "7rem",
  },
  "32": {
    value: "8rem",
  },
  "36": {
    value: "9rem",
  },
  "40": {
    value: "10rem",
  },
  "44": {
    value: "11rem",
  },
  "48": {
    value: "12rem",
  },
  "52": {
    value: "13rem",
  },
  "56": {
    value: "14rem",
  },
  "60": {
    value: "15rem",
  },
  "64": {
    value: "16rem",
  },
  "72": {
    value: "18rem",
  },
  "80": {
    value: "20rem",
  },
  "96": {
    value: "24rem",
  },
  "0.5": {
    value: "0.125rem",
  },
  "1.5": {
    value: "0.375rem",
  },
  "2.5": {
    value: "0.625rem",
  },
  "3.5": {
    value: "0.875rem",
  },
  "4.5": {
    value: "1.125rem",
  },
})
