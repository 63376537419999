import { defineTokens } from '@chakra-ui/react';

export const fontWeights = defineTokens.fontWeights({
  hairline: {
    value: 100,
  },
  thin: {
    value: 200,
  },
  light: {
    value: 300,
  },
  normal: {
    value: 400,
  },
  medium: {
    value: 500,
  },
  semibold: {
    value: 600,
  },
  bold: {
    value: 700,
  },
  extrabold: {
    value: 800,
  },
  black: {
    value: 900,
  },
});
