import { defineRecipe } from '@chakra-ui/react';

export const buttonRecipe = defineRecipe({
  className: 'chakra-button',
  base: {
    display: 'inline-flex',
    appearance: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    position: 'relative',
    borderRadius: 'md',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    borderWidth: '1px',
    borderColor: 'transparent',
    cursor: 'button',
    flexShrink: '0',
    outline: '0',
    lineHeight: '1.2',
    isolation: 'isolate',
    fontWeight: '500',
    transitionProperty: 'common',
    transitionDuration: 'moderate',
    focusVisibleRing: 'outside',
    fontFamily: "'Roboto', sans-serif",
    _disabled: {
      layerStyle: 'disabled',
    },
    _icon: {
      flexShrink: '0',
    },
  },
  variants: {
    size: {
      '2xs': {
        h: '6',
        minW: '6',
        textStyle: 'xs',
        px: '2',
        gap: '1',
        _icon: {
          width: '3.5',
          height: '3.5',
        },
      },
      xs: {
        h: '8',
        minW: '8',
        textStyle: 'xs',
        px: '2.5',
        gap: '1',
        _icon: {
          width: '4',
          height: '4',
        },
      },
      sm: {
        h: '9',
        minW: '9',
        px: '3.5',
        textStyle: 'sm',
        gap: '2',
        _icon: {
          width: '4',
          height: '4',
        },
      },
      md: {
        h: '10',
        minW: '10',
        textStyle: 'sm',
        px: '4',
        gap: '2',
        // _icon: {
        //   width: '5',
        //   height: '5',
        // },
      },
      lg: {
        h: '11',
        minW: '11',
        textStyle: 'md',
        px: '5',
        gap: '3',
        _icon: {
          width: '5',
          height: '5',
        },
      },
      xl: {
        h: '12',
        minW: '12',
        textStyle: 'md',
        px: '5',
        gap: '2.5',
        _icon: {
          width: '5',
          height: '5',
        },
      },
      '2xl': {
        h: '16',
        minW: '16',
        textStyle: 'lg',
        px: '7',
        gap: '3',
        _icon: {
          width: '6',
          height: '6',
        },
      },
    },

    variant: {
      solid: {
        bg: 'actionPrimary.500',
        color: 'white',
        _hover: {
          bg: 'actionPrimary.600',
        },
        _expanded: {
          bg: 'actionPrimary.600',
        },
      },
      subtle: {
        bg: 'colorPalette.subtle',
        color: 'colorPalette.fg',
        _hover: {
          bg: 'colorPalette.muted',
        },
        _expanded: {
          bg: 'colorPalette.muted',
        },
      },
      surface: {
        bg: 'colorPalette.subtle',
        color: 'colorPalette.fg',
        shadow: '0 0 0px 1px var(--shadow-color)',
        shadowColor: 'colorPalette.muted',
        _hover: {
          bg: 'colorPalette.muted',
        },
        _expanded: {
          bg: 'colorPalette.muted',
        },
      },
      outline: {
        borderWidth: '1px',
        borderColor: 'colorPalette.muted',
        color: 'colorPalette.fg',
        _hover: {
          bg: 'colorPalette.subtle',
        },
        _expanded: {
          bg: 'colorPalette.subtle',
        },
      },
      ghost: {
        bg: 'transparent',
        color: 'actionPrimary.500',
        _hover: {
          bg: 'actionPrimary.600',
        },
        _expanded: {
          bg: 'actionPrimary.600',
        },
      },
      plain: {
        color: 'colorPalette.fg',
      },
      link: {
        bg: 'transparent !important',
        color: 'actionPrimary.500',
        _hover: {
          textDecoration: 'underline',
        },
        _expanded: {
          textDecoration: 'underline',
        },
      },
    },
    // colorScheme: {
    //   actionPrimary: {
    //     bg: 'actionPrimary.500',
    //     _hover: {
    //       bg: 'actionPrimary.500/90',
    //     },
    //     _expanded: {
    //       bg: 'actionPrimary.500/90',
    //     },
    //   },
    //   actionSecondary: {
    //     bg: 'actionSecondary.default',
    //     _hover: {
    //       bg: 'actionSecondary.default/90',
    //     },
    //     _expanded: {
    //       bg: 'actionSecondary.default/90',
    //     },
    //   },
    // },
  },
  defaultVariants: {
    size: 'md',
    variant: 'solid',
  },
});
