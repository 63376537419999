import { defineTokens } from '@chakra-ui/react';

export const fontSizes = defineTokens.fontSizes({
  '7xl': {
    value: '41px',
  },
  '6xl': {
    value: '38px',
  },
  '5xl': {
    value: '33px',
  },
  '4xl': {
    value: '30px',
  },
  '3xl': {
    value: '27px',
  },
  '2xl': {
    value: '24px',
  },
  xl: {
    value: '21px',
  },
  lg: {
    value: '18px',
  },
  md: {
    value: '14px',
  },
  sm: {
    value: '12px',
  },
});
